import { useStaticQuery, graphql } from "gatsby"

const useRepos = () => {
  const { allRepository } = useStaticQuery(
    graphql`
      query Repositories {
          allRepository {
            edges {
              node {
                url
                language
                name
                private
                description
                html_url
                topics
                updated_at
              }
            }
          }
      }
    `
  )
  return allRepository
}

export default useRepos


import React from "react"
import Layout from "../../layouts/Layout"
import { Header } from "../../components/Header"
import { Grids } from "../../components/Grids"
import useRepos from "../../hooks/use-repositories"

const SnippetsPage = () => {
  const { edges } = useRepos()

  const metadata = {
    title: "Snippets",
    description:
      "On this site you can find small programs, discrete solutions and code experiments in which you can collaborate or follow your own path from the code base.",
    cover: "/images/covers/cover-nicolascodes.png",
    type: "website",
    path: "/snippets",
  }

  return (
    <Layout metadata={metadata}>
      <Header
        title="Snippets"
        content="On this site you can find small programs, discrete solutions and code experiments in which you can collaborate or follow your own path from the code base."
      />
      <Grids edges={edges} snippets />
    </Layout>
  )
}

export default SnippetsPage
